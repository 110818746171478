<template>
<div class="container padding-container">
    <div class="border-container">
        <div class="topBtns">
            <el-button type="primary" icon="el-icon-plus"
                @click="handleEdit('')" v-if="userType === USER_TYPE.PROVIDER && canEdit">新增教学计划</el-button>
            <el-button type="primary" icon="el-icon-plus"
                @click="handleCopyPlan" v-if="userType === USER_TYPE.SCHOOL && canEdit">新增教学计划</el-button>
        </div>
        <el-row class="info-container" type="flex" align="middle" justify="space-between">
            <div class="info-tabs">
                <span v-if="pageType === 1">资源方</span>
                <span v-if="pageType === 2">学校方</span>
            </div>
        </el-row>
        <div class="table-container">
            <el-table ref="multipleTable" :data="planListNow"
                tooltip-effect="dark" style="width: 100%" size="medium"
                @selection-change="handleSelectionChange"
                :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                :cell-style="{height: '50px',padding: '5px 0'}">
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <el-table-column label="名称" min-width="200" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <div class="fz-bold hover-text-colourful" @click="routerChange(1, scope.row)">
                            {{scope.row.name}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="创建者" min-width="120">
                    <template slot-scope="scope">
                        {{ designer ? designer : (scope.row.creator ? scope.row.creator.name : '-')}}
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" min-width="155">
                    <template slot-scope="scope">
                        {{scope.row.create_time | timeFilter}}
                    </template>
                </el-table-column>
                <el-table-column label="操作" min-width="150" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" class="icon-btn" @click="handleCopy(scope.row)" v-if="userType === USER_TYPE.PROVIDER && canEdit">
                            <el-tooltip placement="top" content="复制课程">
                                <i class="iconfont icon-copy" />
                            </el-tooltip>
                        </el-button>
                        <el-button type="text" class="icon-btn" @click="handleEdit(scope.row)" v-if="canEdit">
                            <el-tooltip placement="top" content="重命名">
                                <i class="iconfont icon-edit" />
                            </el-tooltip>
                        </el-button>
                        <el-button type="text" class="icon-btn" @click="handleDelete(scope.row.id)" v-if="canDelete">
                            <el-tooltip placement="top" content="删除">
                                <i class="iconfont icon-delete" />
                            </el-tooltip>
                        </el-button>
                        <el-button type="text" class="icon-btn" @click="routerChange(1, scope.row)">
                            <el-tooltip placement="top" content="详情">
                                <i class="iconfont icon-check" />
                            </el-tooltip>
                        </el-button>
                        <el-button type="text" class="icon-btn" @click="handleUpdata(scope.row.id)"
                            v-if="auth('SCHOOL') && pageType === 2 && scope.row.update_enable === 1">
                            <el-tooltip placement="top" content="更新">
                                <i class="el-icon-refresh" />
                            </el-tooltip>
                        </el-button>
                    </template>
                </el-table-column>
                <div class="" slot="empty">
                    <no-data></no-data>
                </div>
            </el-table>
        </div>
        <div class="page-container">
            <el-pagination layout="total, sizes, prev, pager, next" background
                @size-change="pageSizeChange"
                @current-change="pageChange"
                :current-page="currentPage"
                :page-sizes="[total, 10, 15, 20, 25]"
                :total="total" :page-size="pageSize">
            </el-pagination>
        </div>
        <div class="dialog-createBySchool">
            <el-dialog title="新增教学计划" :visible.sync="dialogVisible" @close="initEditForm">
                <div class="dialog-content" v-if="dialogVisible">
                    <div class="content-floor">
                        <span class="key">计划名称</span>
                        <el-input v-model="editForm.name"></el-input>
                    </div>
                    <div class="content-floor">
                        <span class="key">计划年份</span>
                        <el-date-picker type="year" value-format="yyyy" style="width: 100%;" v-model="editForm.year" placeholder="选择年份"/>
                    </div>
                    <div class="content-floor">
                        <span class="key">计划模板</span>
                        <el-select v-model="editForm.id"
                            placeholder="请选择">
                            <el-option v-for="item of planList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </div>
                </div>
                <div slot="footer" class="dialog-footer" style="display: flex;justify-content: center">
                        <el-button type="primary" size="medium" @click="confirm">保存</el-button>
                        <el-button class="cancel" size="medium" @click="dialogVisible = false">取消</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</div>
</template>

<script>
import Core from '@/core';
import dayjs from 'dayjs';

const USER_TYPE = Core.Const.USER_TYPE;

export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    data() {
        return {
            pageType: 1,  // 1-资源方 2-学校方
            userType: Core.Data.getUserType(),
            USER_TYPE,

            total: 0,
            currentPage: 1,
            pageSize: 10,
            
            planList: [],
            planListBySchool: [],
            multipleSelection: [],
            
            editForm: {
                id: '',
                name: '',
                year: ''
            },
            schoolId: '',
            dialogVisible: false,
            providerName: Core.Data.getProvider(),
        };
    },
    watch: {
        $route(to, from) {
            this.getPageType()
        }
    },
    computed: {
        planListNow() {
            let planListNow = []
            if (this.pageType === 1) {
                planListNow = this.planList
            } else {
                planListNow = this.planListBySchool
            }
            return planListNow
        },
        canEdit() {  // 0-不可编辑 1-可编辑
            if (this.pageType === 1 && this.auth('PROVIDER')) {
                return 1
            }
            if (this.pageType === 2 && this.auth('SCHOOL')) {
                return 1
            }
            return 0
        },
        canDelete() {
            if (this.pageType === 1 && this.auth('PROVIDER')) {
                return 1
            }
            if (this.pageType === 2 && this.auth('SCHOOL')) {
                if (this.auth('MANAGER')) {
                    return 1
                }
            }
            return 0
        },
        designer() {
            let designer = ''
            if (this.providerName && this.pageType === 1) {
                designer = this.providerName
            }
            return designer
        }
    },
    created() {
        this.getPageType()
        if (this.auth('SCHOOL')) {
            this.getPlanList(1)
        }
        this.editForm.year = new Date().getFullYear().toString();
    },
    methods: {
        auth: Core.Utils.auth,
        getPageType() {  // 切换页面类型
            let path = this.$route.path
            if (path.includes('provider')) {
                this.pageType = 1
            } else if (path.includes('school')) {
                this.pageType = 2
            }
            this.userType = Core.Data.getUserType()

            console.log("getPageType -> this.pageType", this.pageType)
            console.log("getPageType -> this.userType", this.userType)
            this.pageChange(1)
        },

        pageChange(page) {
            this.currentPage = page;
            this.getPlanList();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getPlanList();
        },
        routerChange(key, q = {}) {
            switch (key) {
                case 1:
                    this.$router.push({
                        path: '/plan/plan-detail',
                        query: {
                            plan_id: q.id,
                            plan_type: this.pageType,
                            can_edit: this.canEdit,
                            year: q.year,
                        }
                        });
                    break;
            }
        },

        handleCopyPlan() {
            this.dialogVisible = true
        },
        confirm(){ // 学校方新增排课的确认
                console.log('this.editForm:', this.editForm)
            if (this.editForm.name === '' || this.editForm.id == '' || this.editForm.year === '' ){
                return this.$message.error("请填写完整信息")
            }
            Core.Api.Plan.createBySchool(
                this.editForm.name,
                this.editForm.year,
                this.editForm.id
            ).then((res) => {
                this.$message.success('保存成功!')
                this.getPlanList()
            }).catch((err) => {
                console.log(err);
            });
            this.dialogVisible = false
            this.initEditForm();
        },
        handleUpdata(id){
            // /sync
            this.$confirm('确定要将该教学计划和资源方模板同步吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                Core.Api.Plan.sync(id)
                    .then((res)=>{
                        this.$message.success('同步成功');
                        this.getPlanList()
                    })
            })
        },
        handleEdit(item) {
            if (item) {
                this.editForm.id = item.id;
                this.editForm.name = item.name;
            }
            this.$prompt('请输入新的教学计划名称', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputValue: this.editForm.name
            })
                .then(({ value }) => {
                    this.editForm.name = value;
                    this.editForm.year = dayjs().year();
                    this.save();
                })
                .catch((err) => {
                    this.initEditForm();
                });
        },
        handleCopy(item) {
            Core.Api.Plan.copy(item.id, this.editForm.year).then((res) => {
                this.$message.success('复制成功');
                this.pageChange(1);
            });
        },
        save() {
            const type = this.editForm.id ? 'update' : 'create';
            const data = this.editForm.id
                ? [this.editForm.id, this.editForm.name]
                : [this.editForm.name, this.editForm.year];
            Core.Api.Plan[type](...data).then((res) => {
                this.$message.success('保存成功');
                this.initEditForm();
                this.pageChange(1);
            });
        },
        handleDelete(id) {
            this.$confirm('确定执行删除操作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                Core.Api.Plan.delete(id).then((res) => {
                    this.$message.success('删除成功!');
                    this.pageChange(1);
                });
            });
        },
        initEditForm() {
            this.editForm.id = '';
            this.editForm.name = '';
            this.editForm.year = new Date().getFullYear().toString();
        },
        getPlanList(type) {
            Core.Api.Plan.list(
                this.currentPage,
                this.pageSize,
                '',
                '',
                type ? type : this.pageType,
                this.schoolId
            ).then((res) => {
                // this.planList = res.list;
                if (type) {
                    if (type === 1){
                        this.planList = res.list;
                        this.total = res.count;
                    } else if(type === 2) {
                        this.planListBySchool = res.list;
                        this.total = res.count;
                    }
                } else {
                    if (this.pageType === 1){
                        this.planList = res.list;
                        this.total = res.count;
                    } else if(this.pageType === 2) {
                        this.planListBySchool = res.list;
                        this.total = res.count;
                    }
                }
            });

        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
    .info-container{
        font-size: $font-size-md;
        font-weight: 500;
        color: $color-black;
        line-height: 22px;
        border-bottom: 1px solid rgba(230,234,238,1);
    }
    .dialog-createBySchool {
        .dialog-content {
            .content-floor {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                .key {
                    display: block;
                    width: 120px;
                }
                .el-select{
                    width: 100%;
                }
            }
        }
    }
}
</style>
<style lang="scss">
.dialog-createBySchool{
    .el-dialog{
        width: 500px;
        border-radius: 5px;
        overflow: hidden;
        .el-dialog__header,.el-dialog__footer{
            background:rgba(248,249,250,1);
        }
        .el-dialog__header{
            border-bottom:1px solid rgba(234,237,240,1);
        }
        .el-dialog__footer{
            padding: 10px;
            border-top:1px solid rgba(234,237,240,1);
        }
    }
}
</style>
